<div class="container accessibility-link-group">
  <div class="row">
    <h3 class="col-auto text-ally">{{ pageTitle }}</h3>
    <a class="content-primary link-back col-auto ml-auto" routerLink="/dashboard"> {{ CONSTANTS.backToMyTools }} </a>
  </div>

  <div class="row instruct-centered" *ngIf="isBranchUser">
    <div class="col-md-12"> {{ CONSTANTS.searchInstructions }} </div>
  </div>

  <form [attr.allytmfn]="'contactSearchForm'" *ngIf="isBranchUser" #search="ngForm" name="search" (ngSubmit)="searchByPDN(query, search.valid)"
   class="col-md-6 row">
    <div class="form-group col col-md-8 accessibility-form-input-group">
      <label for="dealership-contact-search-by-pdn">{{ CONSTANTS.pdn }}</label>
      <div class="control-wrapper">
        <div class="input-group">
          <input [attr.allytmfn]="'pdn'" class="form-control col-md-6 col-sm-12" id="dealership-contact-search-by-pdn" [(ngModel)]="query" name="query"
           required pattern="\d{5}|\d{6}" maxlength="6"
           [class.is-invalid]="isSubmitClicked && (search.invalid || isNoDealershipFound)" [attr.allytm-error]="contactsListSearchErrorMessage(search.invalid)">
          <span *ngIf="isSubmitClicked && isPdnEmpty" class="invalid-feedback">
            {{ CONSTANTS.errors.pdnRequired }}
          </span>
          <span *ngIf="isSubmitClicked && (search.invalid || isNoDealershipFound) && !isPdnEmpty" class="invalid-feedback">
              {{ CONSTANTS.errors.pdnNotFound }}
          </span>
        </div>
        <br>
        <button class="btn btn-primary font-weight-bold" type="submit">{{ CONSTANTS.search }}</button>
      </div>
    </div>
  </form>

  <div *ngIf="hasLoaded">
    <div class="row">
        <div class="col-md-12" *ngIf="isBranchUser && dealership">
          <h4 *ngIf="hasLoaded"><b>Contacts for PDN {{ dealership.pdnNumber }} - {{ dealership.dealershipName }}</b></h4>
        </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>{{ CONSTANTS.accountExecutive }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <app-contact-detail [contact]="accountExec" layout="horizontal"></app-contact-detail>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5> {{ CONSTANTS.underWriting }} </h5>
          </div>
        </div>
        <br>
        <div>
        <div class="row">
          <div class="col-md-2">
            <h6>
              <b>{{ CONSTANTS.primaryTeam }}</b>
            </h6>
          </div>

          <div class="col-md-10">
            <div class="row">
              <div class="col-md-4">
                <h6>{{ CONSTANTS.primeTeam }}</h6>
              </div>
              <div class="col-md-4">
                <h6>{{ CONSTANTS.nonPrimeTeam }}</h6>
              </div>
              <div class="col-md-4">
                <h6>{{ CONSTANTS.commercialServices }}</h6>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <div class="row">
              <div class="col-md-4">
                <app-contact-detail [contact]="underwriters[underwriterRoles[0]]"></app-contact-detail>
              </div>
              <div class="col-md-4">
                <app-contact-detail [contact]="underwriters[underwriterRoles[1]]"></app-contact-detail>
              </div>
              <div class="col-md-4">
                <app-contact-detail [contact]="underwriters[underwriterRoles[2]]"></app-contact-detail>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
      </div>
        <div class="row">
          <div class="col-md-2">
            <h6>
              <b>{{ CONSTANTS.managementTeam }}</b>
            </h6>
          </div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-4">
                <h6>{{ CONSTANTS.acquisitionManager }}</h6>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <div class="row">
              <div class="col-md-4">
                <app-contact-detail [contact]="underwriters[underwriterRoles[3]]"></app-contact-detail>
              </div>
            </div>
          </div>
        </div>
        <!--card body ends here-->
      </div>
      <!--card ends here-->
    </div>

    <!--Dealer Support Card starts here-->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>{{ CONSTANTS.dealerSupport }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-2">
            <div class="row">
              <div class="col-4">
                <span class="dealerHelpDesk">{{ CONSTANTS.dealerHelpDesk }}</span>
                <br>
                <img class="icon" src="assets/images/Phone_Icon.svg"/>
                <span class="subTitle">{{ CONSTANTS.call }}</span>
                <br>
                <p class="text-primary"><a class="helpdesk" [href]="tel('866-766-4622')">1-866-766-4622</a></p>
              </div>
              <div class="col-5">
                <span class="generalContacts">{{ CONSTANTS.generalContacts }}</span>
                <br>
                <p class="data">{{ CONSTANTS.genContactTextPartOne }} <a target="_blank" href="https://www.ally.com/dealer/contact-us/" allytmln="general contacts here">{{ CONSTANTS.here }}</a>{{ CONSTANTS.genContactTextPartTwo }}</p>
              </div>
          </div>
          </div>
        </div>
        <!--card body ends here-->
      </div>
      <!--card ends here-->
    </div>

    <!--Sales Card Starts here -->
    <div class="card" *ngIf="salesLinesEmpty">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h5>Sales</h5>
          </div>
        </div>
        <div *ngFor="let line of filteredSalesLineKeys">
          <div class="row">
            <div class="col-md-12">
              <button type="button contact" class="text-primary" aria-label="expand" (click)="toggleLine(line)">
                <i [className]="lineCaretType(line)">
                  <!--Auto Finance button and chevron glyph-->
                </i>
                <b>{{ CONSTANTS[line] }}</b>
              </button>
            </div>
          </div>
          <br>
          <div *ngIf="lineVisible[line]">
            <div *ngFor="let team of salesTeamKeys">
              <div *ngIf="salesTeams[line][team]">
                <div class="row">
                  <!--once clicking the chevron and displays downward this information should display-->
                  <div class="col-md-12">
                    <h6>
                      <b> {{ CONSTANTS.classifications[team] }}</b>
                    </h6>
                  </div>
                </div>
                <hr>
                <div *ngFor="let contact of salesTeams[line][team]">
                  <div class="row">
                    <div class="col-md-2">
                      <h6 class="sales"> {{ CONSTANTS.roles[contact.role] }} </h6>
                    </div>
                    <div class="col-md-10">
                      <app-contact-detail [contact]="contact" layout="horizontal"></app-contact-detail>
                      <!--data goes Supporting Team/ Commercial Service here-->
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sales card body ends here-->
    </div>

      </div>
      </div>