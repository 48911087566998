export const AppStrings = {
  "common": {
    "button": {
      "add":      "Add",
      "cancel":   "Cancel",
      "clear":    "Clear",
      "close":    "Close",
      "continue": "Continue",
      "confirm":  "Confirm",
      "leave":    "Leave",
      "next":     "Next",
      "ok":       "OK",
      "remove":   "Remove",
      "submit":   "Submit"
    },
    "gotoDashboard":     "My Tools",
    "myTools":           "My Tools",
    "returnToMyTools":   "Return to My Tools",
    "editProfile":       "Edit Profile",
    "manageUser":        "Manage User",
    "loading":           "Loading...",
    "optional":          "Optional",
    "pdn":               "PDN",
    "hello":             "Hello",
    "selectOne":         "Select One",
    "state":             "State",
    "searchLabel":       "Search Ally Dash",
    "searchErrorMessage": "Your search must contain at least 3 characters",
    "genericErrorTitle":  "There was an error",
    "genericRequestError": "We can’t complete your request right now. Try again later, or call us for help at 1-866-766-4622."
  },
  "header": {
    "chat": "Chat Available",
    "contacts": "Contacts",
    "help": "Help",
    "logout": "Log Out"
  },
  "footer": {
    "copyright": "2009 - ${ currentYear } Ally Financial Inc.",
    "labels": {
      "about": "About Us",
      "privacy": "Privacy",
      "security": "Security",
      "legal": "Legal",
      "feedback": "Give Feedback"
    }
  },
  "login": {
    "NDAtitleLogin": "Dash Login",
    "NDAlabelUid": "Username",
    "NDAlabelPwd": "Password",
    "NDABtnLogin": "Log In",
    "forgotUsername": "Forgot username?",
    "forgotPassword": "Forgot password?",
    "notRegistered": "Not registered?",
    "lookingFor": "Looking for",
    "requestUsername": "Request a Username",
    "errors": {
      "pendingApprovalError": "Your credentials are inactive pending approval by your dealer admin. Please contact your dealer admin for assistance."
    }
  },
  "splash":{
    "title": "Coming Soon",
    "body":  "Ally Ride Return will soon allow you to quote, return, and purchase Ally-financed vehicles all in once place. Check back later for more information.",
    "button": "Return to Dash"
  },
  "errors": {
    "errUidRequired": "Enter username to continue.",
    "errUsernameReq": "Enter username to continue.",
    "errPwdRequired": "Enter Password to continue.",
    "passwordMatchesUsername": "Your password can’t match your username.",
    "errUidPattern": "Your username must contain at least 8 letters or numbers.",
    "errBothRequired": "Complete this information to continue.",
    "pwdMinlength":  "Password should have at least 8 characters",
    "pdnRequiredError": "Enter PDN to continue.",
    "pdnInvalidError": "The PDN you entered doesn't match our records. Try again.",
    "inValidPwd": "Invalid Password",
    "inValidPwdChars": "Your password can't contain &<>?=%"
  },
  "authErrors": {
    "ERR_000004":"The username you entered is locked. Please contact your Dealer Admin for assistance.",
    "UNKNOWN_ERR":"We can’t complete your request right now. Try again later, or call us for help at 1-866-766-4622.",
    "ERR_000002":"The username or password you entered is invalid. Try again.",
    "ERR_000001":"The username or password you entered is invalid. Try again.",
    "ERR_000008":"The security code you entered doesn't match our records. Try again.",
    "ERR_001001":"We can’t complete your request right now. Try again later, or call us for help at 1-866-766-4622.",
    "ERR_001002":"We can’t complete your request right now. Try again later, or call us for help at 1-866-766-4622.",
    "ERR_000003":"The security code you entered has expired. To continue, select <strong>Resend Security Code</strong> below and enter the new code within 5 minutes of receipt. ",
    "ERR_000010":"The username you entered is suspended.  Please contact your Dealer Admin for assistance.",
    "ERR_000011":"Your account was deactivated due to inactivity per our security policy. For assistance, contact the Dealer Help Desk at 1-866-766-4622.",
    "ERR_000012":"Invalid session",
    "forgotPasswordFlow": {
      "ERR_000001":"Enter username to continue."
    }
  },
  "application": {
    "names": {
      "allyBalloonAdvantageCalculator": "Ally Balloon Advantage Calculator",
      "allyBuyersChoice": "Ally Buyer's Choice (ABC) Calculator",
      "allyVehicleReturnManager": "Ally Payoff Quote and Vehicle Return Manager",
      "creditBalanceAgreementStatements": "Credit Balance Agreement Statements",
      "commercialCreditLineReport": "Commercial Credit Line Report",
      "comTRACDocs": "ComTRAC Full Quote and Document Generator",
      "comTRACQuickQuote": "ComTRAC Quick Quote",
      "dealerTrack": "Dealertrack",
      "manageUsers": "Manage Users",
      "municpalQuote": "Municipal Quote",
      "netQuote": "NetQuote",
      "residualValueLeaseGuide": "Residual Value Lease Guide (RVLG)",
      "routeOne": "RouteOne",
      "smartAuction": "SmartAuction",
      "smartCash": "SmartCash",
      "smartLease": "SmartLease Due date",
      "Ally Ride Return": "Ally Ride Return",
      "allyRideReturnAdmin": "Ally Ride Return Admin",
      "smartLeaseEarlyTerminationVehicleReturn": "Evaluate SmartLease early Termination Vehicle Return",
      "statementOfDealerTransactions": "Statement of Dealer Transactions",
      "vehicleProtectionCenter": "Vehicle Protection Center",
      "vehicleProtectionCenter2": "Vehicle Protection Center - ${ env }2",
      "wholesaleBillingStatement": "Wholesale Billing Statement"
    },
    "environment":{
      "VPC": "-CIT-2",
      "VPC2": "-CIT-1"
    },
    "roles": {
      "smartAuction": {
        "branch": "Branch",
        "eoAdmin": "EO Admin"
      },
      "Ally Ride Return": {
        "dealer": "Dealer",
        "salesperson": "Sales person"
      },
      "smartCash": {
        "eo": "EO",
        "eoBasic": "EO Basic",
        "keyEntryClerk": "Key Entry Clerk",
        "supervisor1": "Supervisor 1",
        "supervisor2": "Supervisor 2",
        "supervisor3": "Supervisor 3",
        "supervisor4": "Supervisor 4",
        "supervisor5": "Supervisor 5",
        "manager": "Manager",
        "region": "Region",
        "support": "Support",
        "spb": "SPB"
      }
    },
    "secondaryRoles": {
      "smartAuction": {
        "seller": "Seller",
        "buyer": "Buyer",
        "buyerAndSeller": "Buyer and Seller"
      }
    }
  },
  "errorsPage": {
    "default": {
      "title": "Something went wrong!",
      "body": "We can't complete your request right now.<br>Try again later, or call us for help at <a href=\"tel: 1-866-766-4622\">1-866-766-4622</a>."
    },
    "pendingRequests": {
      "title": "Your credentials are inactive pending approval by your dealer admin.",
      "body": "Please contact your dealer admin for assistance."
    },
    "pageNotAvailable": {
      "title": "The page you requested is no longer available."
    }
  },

  "dashboard": {
    "title": "Applications",
    "myTools": {
      "navigation": {
        "applications":     "Applications",
        "editProfile":      "Edit Profile",
        "manageUser":       "Manage User",
        "ratesCalculators": "Rates &amp; Calculators",
        "reports":          "Reports",
        "leads":            "Leads",
        "documentManager":  "Document Manager"
      },
      "descriptions": {
        "allyVehicleReturnManager": "Your one-stop payoff quote and return solution for vehicles financed through Ally.",
        "creditBalanceAgreementStatements": "Details funds remitted to Ally to reduce aggregate principal outstandings, resulting in a reduction of floorplan interest charges.",
        "commercialCreditLineReport": "Provides a weekly account of the credit available to your commercial customers.",
        "dealerTrack": "Give you access to dealership F&I software, finance advertising, digital retailing and more.",
        "routeOne": "Provides single sign-on access to a range of financing solutions.",
        "smartAuction": "Facilitates the purchase and sale of wholesale vehicles of all brands.",
        "Ally Ride Return": "AllyRideReturn Link",
        "allyRideReturnAdmin": "AllyRideReturn - Admin Link",
        "smartCash": "Facilitates the settlement of monetary and non-monetary transactions between your dealership and Ally.",
        "statementOfDealerTransactions": "Details dealership finance income and chargeback activity, daily and monthly.",
        "vehicleProtectionCenter": "Helps you manage your Ally Insurance sales, claims and billing efforts.",
        "vehicleProtectionCenter2": "Helps you manage your Ally Insurance sales, claims and billing efforts.",
        "wholesaleBillingStatement": "Provides a monthly statement of certain charges due related to your wholesale vehicle floorplan."
      },
      "ndaLeads": {
        "newPayoffs": "New Payoffs"
      },
      "marketing": {
        "ratesCalculators": {
          "title": "Ally Rates",
          "nationalRates": "National Rates",
          "dealershipProductIndexRates": "Dealership Products — Index Rates",
          "pdf": "(PDF)",
          "nationalRatesTerm": "AllyNationalRatesDocument",
          "dealershipProductsTerm": "DealershipProductsIndexRatesDocument"
        }
      },
      "allyAcademy": {
        "academyTitle": "My Ally Academy",
        "academyShortTitle": "Ally Academy",
        "academyDescription": "Browse courses offered to help enhance your industry knowledge and maximize dealership profitability.",
        "academyLinkText": "View Training"
      }
    }
  },
  "manageUsers": {
    "title": {
      "branch":     "Manage Admins & Users",
      "dealership": "Manage Dealership Users"
    },
    "navigation": {
      "userRequest": {
        "title": {
          "branch":     "User Access",
          "dealership": "User Access"
        },
        "viewPending": "View Pending",
        "searchUsers": "Search Users"
      }
    },
    "viewPendingRequests": {
      "noPendingRequests": "No pending requests"
    },
    "pendingRequest": {
      "submitted": "Submitted",
      "authorizingApps": {
        "instructions": "This user requested access to one or more options that also require access to Dealer Services.",
        "labelApprove": "Allow and Review Options",
        "labelReject": "Reject Request",
        "implemented": "have been implemented."
      },
      "unauthorizingApps": {
        "instructions": "Choose a decision beside each requested option below and select <strong>Confirm</strong> to proceed.",
        "labelApprove": "Allow Access",
        "labelReject": "Reject Request",
        "errorMessage": "Make a selection to continue."
      }
    }
  },
  "profile": {
    "title": "Personal Information",
    "navTitle": "My Profile",
    "navigation": {
      "changePassword": "Change Password",
      "personalInformation": "Personal Information",
      "accountInformation": "Account Information",
      "applicationAndReports": "Applications & Reports",
      "viewDealership": "Associated Dealerships"
    },
    "changePassword": {
      "header": "CHANGE PASSWORD",
      "header1": "Change Password",
      "secondaryHeader1": "Your new password cannot be the same as your previous 6 passwords and must contain 8 to 20 characters, with at least 1 uppercase letter, 1 lowercase letter and 1 number. It cannot contain any unsupported characters",
      "secondaryHeader2": "or your username.",
      "secondaryHeader3": "Your password expired. Your new password cannot be the same as your previous 6 passwords and must contain 8 to 20 characters, no spaces, with at least 1 uppercase letter, 1 lowercase letter and 1 number. It cannot contain any unsupportted characters (&<>%=?) or your username.",
      "currentPassword": "Current Password",
      "newPassword": "New Password",
      "reEnterPassword": "Re-enter New Password",
      "confirmation": "You changed your password.",
      "specialChars": "(&<>%=?)",
      "expiredPassword" : "Expired or Temporary Password",
      "errors": {
        "currentPassword": "Enter password to continue.",
        "newPasswordRequired": "Enter password to continue.",
        "newPasswordGeneric": "Entry doesn't match criteria above. Please try again.",
        "reEnterPassword": "Re-enter password to continue.",
        "passwordMatchesUsername": "Your password can’t match your username.",
        "newPasswordMatchesPreviousPassword": "Your new password cannot be the same as your previous 6 passwords.",
        "invalidCurrentPassword": "Enter a valid current password.",
        "invalidPassword": "Your  password can't contain &<>%=?"
      }
    }
  },
  "modalOtp":{
    "otpOneTimeSecurityCode":"One-Time Security Code",
    "otpCoachMarkText": "Our Dealer Services upgrade includes a one-time security code to help us verify your identity and protect your information.",
    "otpEnterSecurityCode":"Enter Security Code",
    "otpMustVerify": "We must verify your identity before you proceed.",
    "otpConfirmSecurityCode": "Confirm where to send your security code to:",
    "otpConfirmEmail": "We’ll send your security code to:",
    "otpTechIssueText":"If you have any technical issues, call the Dealer Help Desk at",
    "otpDealerHelpDeskNo":"1-866-766-4622",
    "otpSendSecCodeBtn":"Send Security Code",
    "otpCancelBtn":"Cancel",
    "otpSentSecCodeTxt":"We sent you a security code.",
    "otpEnterCodeTxt":"Please keep this window open so you can enter the code below.",
    "otpCodeWillTxt":"Your code will",
    "otpExpireMinTxt":"expire 5 minutes",
    "otpAfterSentTxt":"after it's sent.",
    "otpCodeSentTxt":"Code sent to",
    "otpSendEmailInsteadTxt":"Send as Email Instead",
    "otpSecCodeTxt":"SECURITY CODE",
    "otpRegTrustedDevice": {
      "headline":    "Want to receive fewer security code requests?",
      "textBold":    "Check this box",
      "textRegular": "if you would like to skip this step in the future when you log in from this device."
    },
    "otpSubmitBtn":"Submit",
    "otpResendSecCodeTxt":"Resend Security Code",
    "enterOtpNumber":"Enter security code to continue.",
    "sixNumbers":"Your security code must contain at least 6 characters.",
    "mustSixDigit":"Your security code should not contain alphabets and special characters.",
    "smsFactor": "text message",
    "emailFactor": "email",
    "sendError": "Make a selection to continue.",
    "otpSendAlternateCode": "Send as ${method} Instead"
  },
  "modalSessionTimeOut":{
    "header":"Your Session is About to Expire",
    "message":"You will be logged out in ${countdown}. Do you want to continue?",
    "continueLabel":"Stay Signed in",
    "signoutLabel":"Log Out"
  },
  "registration": {
    "ally": {
      "application": {
        "creditBalanceAgreementStatements":                 "Credit Balance Agreement Statements",
        "commercialCreditLineReport":    "Commercial Credit Line Report",
        "manageUsers":                   "Manage Users",
        "routeOne":                      "RouteOne",
        "smartAuction":                  "SmartAuction",
        "smartCash":                     "SmartCash",
        "Ally Ride Return":                "Ally Ride Return",
        "allyRideReturnAdmin":           "Ally Ride Return Admin",
        "statementOfDealerTransactions": "Statement of Dealer Transactions",
        "vehicleProtectionCenter":       "Vehicle Protection Center",
        "wholesaleBillingStatement":     "Wholesale Billing Statement"
      },
      "roles": {
        "smartAuction": {
          "branch":  "Branch",
          "eoAdmin": "EO Admin"
        },
        "smartCash": {
          "eo":            "EO",
          "eoBasic":       "EO Basic",
          "keyEntryClerk": "Key Entry Clerk",
          "supervisor1":   "Supervisor 1",
          "supervisor2":   "Supervisor 2",
          "supervisor3":   "Supervisor 3",
          "supervisor4":   "Supervisor 4",
          "supervisor5":   "Supervisor 5",
          "manager":       "Manager",
          "region":        "Region",
          "support":       "Support",
          "spb":           "SPB"
        },
        "Ally Ride Return": {
          "branch":  "Branch",
          "eoAdmin": "EO Admin"
        }
      },
      "region": {
        "central" :     "Central",
        "northeast" :   "Northeast",
        "pacificWest" : "Pacific West",
        "remarketing":  "Remarketing",
        "southeast" :   "Southeast",
        "west" :        "West"
      },
      "header": {
        "title":       "Dealer Services Registration - Ally User",
        "contactInfo": ""
      },
      "step1": {
        "stepTitle":    "Review and Choose Role",
        "title":        "Review Information",
        "firstName":    "First Name",
        "lastName":     "Last Name",
        "cellPhone":    "Cell Phone",
        "email":        "Email",
        "roleheader":   "Choose Role",
        "role":         "Ally Role",
        "roleLabels": {
          "helpdesk": "Help Desk",
          "admin": "Ally Admin",
          "user": "Ally User"
        },
        "errorMessages": {
          "selectedRole": "Make a selection to continue."
        }
      },
      "step2": {
        "stepTitle":      "Select Applications",
        "title":          "Select Applications & Reports (Optional)",
        "header2": " to complete your registration:",
        "header1": "You can choose one or more options below to request access, or skip and select ",
        "selectARole": "Make a selection to continue.",
        "selectboxDefault": "Select One",
        "applicationRole": "APPLICATION ROLE",
        "applicationRegion": "REGION",
        "errorFound": "There is 1 error in the form you submitted.",
        "reviewError": "Review error.",
        "continue": "Continue",
        "cancel": "Cancel",
        "submit": "Submit",
        "error": {
          "pendingApproval": "Your credentials are inactive pending approval by your manager. Please contact your manager for assistance."
        }
      },
       "confimation": {
        "submitted": "Registration Submitted",
        "content": "After your manager reviews your access request, you'll receive an email with instructions on how to proceed.  If you have any questions, contact your manager.",
        "linkText":"Go to Ally Pulse"
      }
    },
    "dealership": {
      "application": {
        "creditBalanceAgreementStatements": "Credit Balance Agreement Statements",
        "commercialCreditLineReport":       "Commercial Credit Line Report",
        "routeOne":                         "RouteOne",
        "smartAuction":                     "SmartAuction",
        "smartCash":                        "SmartCash",
        "statementOfDealerTransactions":    "Statement of Dealer Transactions",
        "vehicleProtectionCenter":          "Vehicle Protection Center",
        "wholesaleBillingStatement":        "Wholesale Billing Statement",
        "Ally Ride Return":                   "Ally Ride Return"
      },
      "header": {
        "title":       "Dealer Services Registration",
        "contactInfo": "Dealer Help Desk: 1-866-766-4622"
      },
      "step1": {
        "stepTitle":    "Provide Information",
        "title":        "Provide Information",
        "firstName":    "First Name",
        "lastName":     "Last Name",
        "cellPhone":    "Cell Phone",
        "email":        "Email",
        "confirmEmail": "Confirm Email",
        "formInvalidSingular": "There is an error in the form you submitted.",
        "formInvalidPlural":   "There are ${ n } errors in the form you submitted.",
        "reviewErrors":        "Review errors.",
        "errorMessages": {
          "firstName":    "Enter first name to continue.",
          "lastName":     "Enter last name to continue.",
          "cellPhone":    "Enter a valid cell phone number to continue.",
          "email":        "Enter email address to continue.",
          "emailConfirmation": {
            "required":   "Re-enter email address to continue.",
            "matchOther": "The email address and confirmation you entered don’t match. Please try again."
          }
        }
      },
      "step2": {
        "stepTitle":      "Choose Dealership",
        "title":          "Choose Dealership",
        "instructions":   "Enter your PDN and select a state to access associated tools and resources.",
        "dealershipName": "Dealership Name",
        "searchBy":       "Search By",
        "resultSingular": "${ n } result found",
        "resultPlural":   "${ n } results found",
        "resultForPdn":   "for PDN ${ pdn }",
        "noSelectionErrorMessage": "Make a selection to continue.",
        "errorMessages": {
          "pdn": {
            "required":  "Enter PDN to continue.",
            "pattern":   "The PDN you entered doesn’t match our records. Try again.",
            "minlength": "The PDN you entered doesn’t match our records. Try again.",
            "notFound": "The PDN you entered doesn’t match our records. Try again."
          },
          "dealershipName": {
            "required":  "Enter dealership name to continue.",
            "pattern":   "Your dealership name can’t contain special characters.",
            "minlength": "Your dealership name must contain at least 3 characters.",
            "notFound": "The dealership name you entered doesn’t match our records. Try again."
          },
          "selectedState": {
            "required": "Select state to continue."
          },
          "dealershipPdn": {
            "required": "Make a selection to continue."
          },
          "services": {
            "notFoundPdn":            "The PDN you entered doesn’t match our records. Try again.",
            "notFoundDealershipName": "The dealership name you entered doesn’t match our records. Try again."
          },
          "mismatchPdnState": {
            "title": "We're having trouble finding you",
            "message": "Check to make sure your information is correct, or contact your dealer admin for help.",
            "tryAgain": "Try again"
          }
        }
      },
      "step3": {
        "stepTitle": "Select Applications",
        "title": "Select Applications & Reports (Optional)",
        "selectARole": "Make a selection to continue.",
        "selectboxDefault": "Select One",
        "applicationRole": "APPLICATION ROLE",
        "errorFoundSingular": "There is an error in the form you submitted.",
        "errorFoundPlural": "There are ${ n } errors in the form you submitted.",
        "reviewError": "Review error.",
        "continue": "Continue",
        "header2": " to proceed:",
        "header1": "You can select one or more options below to request access for this dealership, or skip and select ",
        "cancel": "Cancel",
        "submit": "Continue"
      },
      "step4": {
        "stepTitle": "Create Credentials",
        "title": "Create Credentials",
        "header": "Your username must contain exactly 8 letters and/or number. Your password must contain between 8-20 characters, no spaces, with at least 1 uppercase letter, 1 lowercase letter and 1 number. It cannot contain any unsupported characters (&< > % = ?) or your username.",
        "username": "Username",
        "usernameError": "Enter user name to continue.",
        "password": "Password",
        "passwordEmptyError": "Enter password to continue.",
        "passwordUppercaseError": "Your password must contain at least 1 upper case letter.",
        "passwordLowercaseError": "Your password must contain at least 1 lower case letter.",
        "passwordNumberError": "Your password must contain at least 1 number.",
        "passwordLengthError": "Your password must contain at least 8 to 20 characters.",
        "passwordMatchesUsername": "Your password can’t match your username.",
        "reenterPassword": "Re-enter Password",
        "reenterPasswordError": "Re-enter password to continue.",
        "formInvalidSingular": "There is an error in the form you submitted.",
        "formInvalidPlural":   "There are ${ n } errors in the form you submitted.",
        "reviewErrors":        "Review errors.",
        "passwordGreaterThanMax": "Your password cant't contain more than 8 to 20 characters.",
        "usernameHasSpecialCharacters": "Your username must contain at least 8 letters or numbers.",
        "usernameGenericError": "Your username must contain 8 letters or numbers.",
        "usernameNotUnique": "The user name you provided can’t be used because it’s associated with another account. Please try again.",
        "passwordNotMatchWithReEnterPassword": "Password and Re-enter Password doesn't match.",
        "inValidPwd": "Invalid Password",
        "inValidPwdChars": "Your password can't contain &<>?=%"
      },
      "smartAuctionRoles": {
        "dealer": "Dealer",
        "salesperson": "Salesperson"
      },
      "smartAuctionSecondaryRoles": {
        "seller": "Seller",
        "buyer": "Buyer",
        "buyerAndSeller": "Buyer and Seller"
      },
      "allyRideReturnRoles": {
        "dealer": "Dealer",
        "salesperson": "Salesperson"
      },
      "confimation": {
        "submitted": "Registration Submitted",
        "content": "<p>Your dealership admin will review your submission and send you an email with instructions on how to proceed within 1 to 2 business days.</p> <p>If you have any questions, contact your dealership admin.</p>"
      }
    },
    "wizard": {
      "startOver": "Start Over"
    },
    "passwordComplexity": {
      "charRange": "8-20 characters",
      "uppercase": "1 uppercase letter",
      "lowercase": "1 lowercase letter",
      "number": "1 number"
    }
  },
  "ndaOperationHours":{
    "contactUs": "Contact Us",
    "helpDesk": "Ally Auto Dealer Help Desk",
    "helpDeskPhone": "1-866-766-4622",
    "weekdays": "Monday-Friday",
    "weekdayHours": "9:30 am - 6:30 pm ET",
    "saturday": "Saturday",
    "saturdayHours": "9 am - 6 pm ET"
  },
  "confirmPassword":{
    "pageTitle": "Create New Password",
    "thankYou": "Thank You",
    "pwdChanged": "Your password has been changed.",
    "goToLogin": "Go to Login",
    "password": "Password",
    "reenterPassword": "Re-enter Password",
    "cancel": "Cancel",
    "submit": "Submit",
    "passwordCriteria":"Your new password cannot be the same as your previous 6 passwords and must contain 8 to 20 characters, no spaces, with at least 1 uppercase letter, 1 lowercase letter and 1 number. It cannot contain any unsupported characters (&< > % = ?) or your username.",
    "passwordEmptyError": "Enter password to continue.",
    "passwordUppercaseError": "Your password must contain at least 1 upper case letter.",
    "passwordLowercaseError": "Your password must contain at least 1 lower case letter.",
    "passwordNumberError": "Your password must contain at least 1 number.",
    "passwordLengthError": "Your password must contain at least 8 to 20 characters.",
    "passwordGreaterThanMax": "Your password cant't contain more than 8 to 20 characters.",
    "passwordHasSpecialCharacters": "Your username must contain at least 8 letters or numbers.",
    "passwordMatchesUsername": "Your password can’t match your username.",
    "usernameGenericError": "Your username must contain 8 letters or numbers.",
    "usernameNotUnique": "The user name you provided can’t be used because it’s associated with another account. Please try again.",
    "passwordNotMatchWithReEnterPassword": "Re-enter password to continue.",
    "passwordMatchesPreviousPassword": "Your new password cannot be the same as your previous 6 passwords.",
    "invalidPwd":"Your  password can't contain &<>%=?"
  },
  "errorForgotPassword":{
    "title": "Hmm. We're having trouble finding you.", 
    "message":"Try entering your information again or contact your dealer admin for assistance.",
    "button":"Try again"
  },
  "getHelpModule":{
    "pageTitle": "Forgot Password",
    "dealerHelpDeskText":"Enter the username and primary dealership number (PDN) associated with your account. If you don't know your PDN, contact your dealer admin or the help desk.",
    "dealerHelpDeskNo": "1-866-766-4622",
    "usernameText": "ENTER YOUR USERNAME",
    "pdnText": "ENTER YOUR PDN",
    "contactUs": "Contact Us",
    "dealerHelpDeskName": "Ally Auto Dealer Help Desk",
    "dealerHelpDeskMondayToFridayTime": "Mon-Fri, 9:30 am - 6:30 pm ET",
    "dealerHelpDeskSaturdayTime": "Sat, 9 am - 6 pm ET",
    "smartAuctionHelpDeskName": "SmartAuction Help Desk",
    "smartAuctionHelpDeskNo": "1-877-273-5572",
    "smartAuctionHelpDeskMondayToFridayTime": "Mon-Fri, 8 am - 7 pm ET",
    "submitBtn": "Submit",
    "cancelBtn": "Cancel",
    "invalidPdn":"Enter a valid PDN to continue.",
    "invalidUsername":"Enter a valid username to continue.",
    "errors": {
      "lockedUserErrorMessage":"The user id you entered is locked. Please contact your Dealer Admin for assistance.",
      "suspendedUserErrorMessage":"The user id you entered is suspended. Please contact your Dealer Admin for assistance.",
      "deactivatedUserErrorMessage":"Your account was deactivated due to inactivity per our security policy. For assistance, contact the Dealer Help Desk at 1-866-766-4622."
    }
  },
  "pdnVerification":{
    "pageTitle": "Register for Portal Access",
    "pageContent": "To get started, please enter your Primary Dealer Number (PDN). If you don't know your PDN contact your dealership's office manager.",
    "pdnText": "PDN"
  },
  "dealerAppAccessError":{
    "adminApprovalForDealer":"Access to this option requires approval by your dealer admin.",
    "adminApprovalForBranch":"Access to this option requires approval by your manager.",
    "adminAssistance":"Contact your dealership admin for assistance.",
    "requestAccess":"To request access, select",
    "editProfile": "Edit Profile",
    "andChoose": "and choose",
    "appReports": "Applications and Reports",
    "approvalNeeded": "Approval Needed"
  },
  "personalInformation":{
    "title": "Account Information",
    "name": "Name",
    "firstName":"First Name",
    "lastName":"Last Name",
    "cellPhoneOpt":"Cell Phone (Optional)",
    "cellPhone": "Cell Phone",
    "email": "Email",
    "dealerAdmin":"Dealer Admin",
    "dealerAdminNotAssigned": "Not assigned",
    "dealerAdmincallfailureMessage":"A system error occurred. Try again later, or call the Dealer Help Desk at 1-866-766-4622 for assistance.",
    "reEnterEmail":"Re-enter Email",
    "userStatus": "User Status",
    "userActive": "Active",
    "userSuspend": "Suspended",
    "formInvalidSingular": "There is an error in the form you submitted.",
    "formInvalidPlural":   "There are ${ n } errors in the form you submitted.",
    "reviewErrors":        "Review errors.",
    "editLink": "Edit",
    "drawerTitle":"You can edit your information below. ",
    "firstNameError" : "Enter first name to continue.",
    "firstNameSpecialCharErr" : "Your first name can’t contain special characters.",
    "firstNameMin2LengthErr" : "Your first name must contain at least 2 characters.",
    "lastNameError" : "Enter last name to continue.",
    "lastNameSpecialCharErr" : "Your last name can’t contain special characters.",
    "lastNameMin2LengthErr" : "Your last name must contain at least 2 characters.",
    "cellPhoneError" : "Enter a valid cell phone number to continue.",
    "emailEmptyError" : "Enter email address to continue.",
    "emailInvalidError" : "Enter a valid email address.",
    "reEnterEmailEmptyError" : "Re-enter email address to continue.",
    "reEnterEmailError" : "The email address you entered don't match. Please try again.",
    "saveChanges" : "Don’t Save Changes?",
    "successTitle": "Thank You",
    "profileUpdated" : "You updated your personal information.",
    "changeWillLost" : "If you leave without submitting the edits, your changes will be lost.",
    "changeRoleWarningText": "To change your user role contact Ally Help Desk at <a href='tel:1-800-545-1960'>1-800-545-1960</a>.",
    "userRole": "user role",
    "allyAdminNonApprovedDealerUserEditMessage": "The user's account requires dealer admin approval. Contact the dealer admin for assistance."
  },
  "applicationAndReports":{
    "title": "APPLICATIONS & REPORTS",
    "addLink": "Add",
    "applicationRole" : "APPLICATION ROLE",
    "secondaryRole": "SECONDARY ROLE",
    "auctionAccessID": "AUCTIONACCESS ID",
    "applicationRegion" : "REGION",
    "selectOne" : "Select One",
    "selectARole": "Make a selection to continue.",
    "auctionAccessPreamble": "This 9-digit number (also known as 100 million number), which always starts with a 1, is assigned by AuctionACCESS to help identify vehicle buyers. For help with your number, or to get one, you can:",
    "auctionAccessVisit": "Visit ",
    "auctionAccessURL": "auctionaccess.com",
    "auctionAccessCall": "Call AuctionACCESS customer support at 1-205-414-2739",
    "auctionAccessEmail": "Email them at ",
    "auctionAccessEmailAddress": "customerservice@auctionaccess.com",
    "invalidAuctionAccessIDError": "Enter AuctionACCESS ID to continue.",
    "minlengthAuctionAccessIDError": "Enter 9 digit AuctionAccess ID to continue.",
    "modalTitle": "Access Request Submitted",
    "requestConfirmation": "After your dealership admin reviews your request, you'll receive an email with instructions on how to proceed. If you have any questions, contact your dealer admin.",
    "requestConfirmationForAllyUser": "After your manager reviews your request, you'll receive an email with instructions on how to proceed. If you have any questions, contact your manager.",
    "submitBtn": "Submit",
    "exceptionError" : "We can’t complete your request right now. Try again later, or call us for help at 1-866-766-4622.",
    "addDealership": "+ Add Associated Dealership",
    "appAndReports": "Application & Reports",
    "primaryDealership": "PRIMARY DEALERSHIP",
    "activeApplications": "ACTIVE APPLICATIONS",
    "addAssocDealership": "Add Associated Dealership",
    "reviewRequest": "After your dealership admin reviews your request, you'll receive an email with instructions on how to proceed. If you have any questions, contact your dealer admin.",
    "pdn": "PDN",
    "none": "None",
    "remove" : "Remove",
    "assocDealership": "ASSOCIATED DEALERSHIPS",
    "haveAccessForAllOptions" : "You have access to all available options.",
    "appSelectionMessage" : "Select one or more options to grant access:",
    "appRemoveTitle": "Select the options you'd like to remove:",
    "areYouSure" : "Are You Sure?",
    "removeConfirmation": "You'll no longer have access to whatever you choose.<br/>Select <strong>Remove</strong> to proceed.",
    "removeAppsAreYouSure": "Removing all applications and/or reports will end your access to Dealer Services for the  associated dealership. Select <strong>Remove</strong> to confirm.",
    "noAppsToRemove": "You have no options to remove."
  },
  "associatedDealership":{
    "title":"ASSOCIATED DEALERSHIPS",
    "addDealership":"Add Dealership",
    "addAssociatedDealership" : "Add Associated Dealership",
    "btnAddDealership":"+ Add Dealership",
    "addDealershipDescription":"Request to add one or more dealership to your network",
    "noSelectionErrorMessage": "Make a selection to continue.",
    "searchByExistingPdn": "You entered a PDN or dealership name thats's already active in your profile. Try again."
  },
  "addDealershipChooseAppsAndReports":{
    "header": "Choose Applications & Reports",
    "applicationRole": "APPLICATION ROLE",
    "bodyContent": "You can select one or more options below to request access for this dealership. Select <strong>Submit</strong> to complete your request",
    "successHeader": "Dealership Request Submitted",
    "failureHeader": "We can't complete your request right now.<br>Try again later, or call us for help at <a href=\"tel: 1-866-766-4622\">1-866-766-4622</a>.",
    "successBodyContentForAdmin": "Your dealership has been added along with the application & reports that you selected.",
    "successBodyContent": "After your dealership admin reviews your request. you'll receive an email with instructions on how to proceed. If you have any questions, contact your dealership admin.",
    "noSelectionErrorMessage": "Make a selection to continue.",
    "application": {
      "creditBalanceAgreementStatements":                 "Credit Balance Agreement Statements",
      "commercialCreditLineReport":    "Commercial Credit Line Report",
      "leads":                         "Leads",
      "statementOfDealerTransactions": "Statement of Dealer Transactions",
      "wholesaleBillingStatement":     "Wholesale Billing Statement",
      "Ally_Ride_Return":                "Ally Ride Return",
      "Ally_Ride_Return_Admin":          "Ally Ride Return Admin",
      "manageUsers":                   "Manage Users"
    }
  },
  "searchUsers":{
    "title" : "Search Users",
    "updateProfile" : "Find a user to update their status, access or password.",
    "name" : "Name",
    "errEmptyName" : "Enter name to search.",
    "titleName" : "NAME",
    "dealership" : "DEALERSHIP",
    "status": "STATUS",
    "search" : "Search",
    "clear" : "Clear",
    "showRecordTotal" : "Showing ${begin} - ${end} of ${total} users",
    "nameError" : "Your name must contain at least 2 characters.",
    "searchEmpty" : "The name you entered doesn’t match our records. Try again.",
    "tableCaption" : "User Search Results",
    "none" : "None"
  },
  "searchUserDetails":{
    "backToSearch": "Back To Search Results",
    "contentHeader": "View Access for",
    "personalInfoTitle": "Personal Information",
    "name": "NAME",
    "firstName":"FIRST NAME",
    "lastName":"LAST NAME",
    "cellPhone": "CELL PHONE",
    "email": "EMAIL",
    "userName": "USER NAME",
    "dealershipName": "DEALERSHIP NAME",
    "primaryDealershipName": "PRIMARY DEALERSHIP",
    "dealerUser": "Dealership User",
    "dealerAdmin": "Dealership Admin",
    "role": "ROLE",
    "userStatus": "USER STATUS",
    "appsAndReportsTitle": "APPLICATIONS & REPORTS",
    "passwordTitle": "PASSWORD",
    "noApps": "None",
    "editLink": "Edit",
    "removeUserLink": "Remove user",
    "addAppsLink": "Add",
    "removeAppsLink": "Remove",
    "createPasswordLink": "Create Temporary Password",
    "currentPassword": "Current Password",
    "pdn" : "PDN",
    "addDealership" : "+ Add Associated Dealership",
    "profileUpdateSuccess": "You updated your information",
    "adminProfileUpdateSuccess": "You've updated the user's profile",
    "pending": "Pending",
    "associatedDealership" : "ASSOCIATED DEALERSHIPS",
    "singleAppAddSuccess": "You granted access to the selected option.",
    "multipleAppAddSuccess": "You granted access to the selected options."
  },
  "removeUser":{
    "title": "Remove User",
    "removeButtonText": "Remove User",
    "bodyContent": "Once you remove this user, they can no longer access Dealer Services. Select <strong>Remove User</strong> to confirm."
  },
  "addRemoveApps":{
    "addAppsDefaultTitle": "Select one or more options to request access.",
    "removeAppsDefaultTitle": "Select the options you’d like to remove:",
    "noAppsToRemoveTitle": "You have no options to remove",
    "noAppsToAddTitle": "You have access to all available options",
    "header": "Remove Option",
    "bodyContent": "Removing this application or report will end the user's access to it.",
    "secondaryBodyContent": "Select <strong>Remove</strong> to confirm.",
    "dealerAccessRemoveBodyContent" : "Removing these applications and/or report will end the user's access to Dealer Services for the associated dealership."
  },
  "createPassword": {
    "title" : "Create a Temporary password",
    "bodyContent": "The temporary password you create cannot be same as the recipient's previous 6 passwords and must contain 8 to 20 characters, no spaces, with at least 1 uppercase letter, 1 lowercase letter and 1 number. It cannot contain any unsupported characters (&<>%=?) or the recipient's username.",
    "message": "The user's status will automatically update to <strong>Active</strong>.",
    "enterPassword": "Enter Password",
    "confirmPassword": "Confirm Password",
    "createPasswordButtonText": "Create Password",
    "tempPasswordConfirmation": "You created a temporary password",
    "statusChangeIndicatorText": "The user’s status will automatically update to <strong>Active</strong> after they sign in with this temporary password and create their new password.",
    "errors": {
      "currentPassword": "Enter password to continue.",
      "newPasswordRequired": "Enter password to continue.",
      "newPasswordGeneric": "Entry doesn't match criteria above. Please try again.",
      "reEnterPassword": "Re-enter password to continue.",
      "passwordMatchesUsername": "Your password can’t match your username.",
      "passwordMatchesPreviousPassword": "Your new password cannot be the same as your previous 6 passwords.",
      "invalidPwd": "Your  password can't contain &<>%=?"
    }
  },
  "allyAdminUser":{
    "errorMessages": {
      "pdn": {
        "required":  "Enter PDN to continue.",
        "pattern":   "PDN can't contain special characters.",
        "minLength": "PDN must contain at least 5 digits."
      },
      "dealershipName": {
        "required":  "Enter dealership name to continue.",
        "pattern":   "Your dealership name can’t contain special characters.",
        "minlength": "Your dealership name must contain at least 3 characters.",
        "notFound": "The dealership name you entered doesn’t match our records. Try again."
      },
      "userName": {
        "required":  "Enter username to continue.",
        "minlength":   "Your username must contain at least 8 letters or numbers."
      },
      "emailAddress": {
        "required":  "Enter email address to continue.",
        "pattern":   "The email you entered doesn’t match our records. Try again.",
        "invalidEmail" : "Invalid EmailId."
      },
      "name": {
        "fnRequired":  "Enter firstname to continue.",
        "lnRequired":  "Enter lastname to continue.",
        "fnMinLength":  "Your firstname must contain at least 2 characters.",
        "lnMinLength":  "Your lastname must contain at least 2 characters."
      },
      "selectedState": {
        "required": "Make a selection to continue."
      },
      "dealershipPdn": {
        "required": "Make a selection to continue."
      },
      "services": {
        "notFoundPdn":            "The PDN you entered doesn’t match our records. Try again.",
        "notFoundDealershipName": "The dealership name you entered doesn’t match our records. Try again."
      }
    },
    "selectboxDefault": "Select One",
    "resultSingular": "${ n } result found",
    "resultPlural":   "${ n } results found",
    "resultForPdn":   "for PDN ${ pdn }",
    "showRecordTotal" : "Showing ${begin} - ${end} of ${total} PDN",
    "showUserRecordTotal" : "Showing ${begin} - ${end} of ${total} Users",
    "titleName" : "NAME",
    "dealership": "DEALERSHIP",
    "status" : "USER STATUS",
    "title" : "Search Users",
    "allyUser" : "Ally User",
    "dealershipItemTmpl": "PDN ${ pdnNumber } - ${ dealershipName }",
    "dealershipItemTmplPending" : "PDN ${ pdnNumber } - ${ dealershipName } (${ dealershipStatus })",
    "updateStatus" : "Find a user to update their status, access or password.",
    "searchBy" : "Search By",
    "pdn" : "PDN",
    "name": "Name",
    "userName" : "Username",
    "emailAddress" : "Email Address",
    "firstName": "First Name",
    "lastName": "Last Name",
    "dealershipName": "Dealership Name",
    "state": "State",
    "continue": "Continue",
    "cancel":"Cancel",
    "noRecords" : "0 results found for \"${searchCriteria}\"",
    "dealershipAdmin" : "Dealership Admin"
  },
  "oktaAllyRoles": {
    "Ally User": "Ally User",
    "Ally Admin": "Ally Admin",
    "Help Desk": "Help Desk"
  },
  "warningModal": {
    "question" : "Don't Save Changes?",
    "detailMessage" : "If you leave without submitting the edits, your changes will be lost."
  },
  "passwordExpiry": {
    "passwordInfo":"Password About to Expire",
    "changepassword":"Change Password",
    "continue":"Continue to Dealer Services",
    "smartAuctionContinue":"Continue to SmartAuction",
    "bodyContent1":"Your password will expire on ",
    "bodyContent2":". Would you like to change it?"
  },
  "search": {
    "search" : "Search"
  },
  "myContacts": {
    "flyout": {
      "viewAllContacts": "View all contacts"
    },
    "contactDetail": {
      "name": "Name",
      "call": "Call",
      "email": "Email",
      "unassigned": "{UNASSIGNED}"
    },
    "contactsList": {
      "contact": "Contacts",
      "searchContacts": "Search Contacts",
      "backToMyTools": "Back To My Tools",
      "searchInstructions": "Enter a PDN to view the primary points of contact for a specific dealership.",
      "accountExecutive": "Account Executive",
      "underWriting": "Underwriting",
      "primaryTeam": "Primary Team",
      "primeTeam": "Prime Team",
      "nonPrimeTeam": "Non-Prime Team",
      "commercialServices": "Commercial Services",
      "managementTeam": "Management Team",
      "acquisitionManager": "Acquisition Manager",
      "dealerHelpDesk": "Dealer Help Desk",
      "dealerSupport": "Dealer Support",
      "call": "Call",
      "generalContacts": "General Contacts",
      "here": "here",
      "genContactTextPartOne": "You can find our full list of contacts",
      "genContactTextPartTwo": ". If you can't reach anyone, call your regional business center.",
      "errors": {
        "pdnRequired":  "Make a selection to continue.",
        "pdnNotFound": "The PDN you entered doesn’t match our records. Try again."
      }
    },
    "salesTeams": {
      "AUTO_FIN": "Auto Finance",
      "INS": "F&I and Dealer Insurance",
      "REMRKT": "Remarketing",
      "WH_DL": "Wholesale & Dealer Loans"
    },
    "flyoutRoles": {
      "SALES_AUTO_FIN_ACC_EXE": "Account Executive",
      "UNDRW_PRIME": "Primary Underwriter",
      "UNDRW_COMM_SERV": "Commercial Services Underwriter"
    },
    "classifications": {
      "REMRKT": "Remarketing",
      "SALES": "Sales",
      "INS": "F&I and Dealer Insurance",
      "SUPP_TEAM": "Supporting Team",
      "PRI_ACC_EXE": "Primary Account Executives",
      "WH_DL": "Wholesale & Dealer Loans",
      "UNDRW": "Underwriting",
      "AUTO_FIN": "Auto Finance",
      "MGMT_TEAM": "Management Team",
      "PRI_TEAM": "Primary Team",
      "helpDesk": "Dealer Help Desk",
      "dealerHelpDesk": "Dealer Help Desk"
    },
    "mappings": {
      "DealerTrack": "Dealertrack"
    },
    "roles": {
      "ADD_ACC_EXE": "Additional Account Executive",
      "COMM_LEN_QL": "Commercial Lending",
      "SALES": "Sales",
      "ACC_SERV_REP_QL": "Account Services Representative",
      "ACQ_MGR_QL": "Acquisition Manager",
      "COMM_SERV_SL_QL": "Commercial Services",
      "SNR_PRIME": "Senior Prime",
      "SNR_UNDRWTR_QL": "Senior Underwriter",
      "RV": "RV",
      "BUS_DEV_MGR_QL": "Business Development Manager",
      "COMM_LEN": "Commercial Lending",
      "ACC_EXE": "Account Executive",
      "UWC_QL": "UWC",
      "UWC": "UWC",
      "REG_VP_QL": "Regional Vice President",
      "DIR_SALES": "Director of Sales",
      "COMM_SERV_QL": "Commercial Services - Primary",
      "INS": "F&I and Dealer Insurance",
      "SNR_N_PRIME": "Senior Non-Prime",
      "AREA_SAL_MGR": "Area Sales Manager",
      "BUS_DEV_MGR": "Business Development Manager",
      "ADD_DIR_SALES": "Additional Director of Sales",
      "ACQ_MGR": "Acquisition Manager",
      "ADD_ACC_EXE_QL": "Additional Account Executive",
      "RINS_QL": "Reinsurance",
      "RINS": "Reinsurance",
      "INS_QL": "Insurance",
      "REG_VP": "Regional Vice President",
      "ACC_EXE_QL": "Account Executive",
      "SNR_UNDRWTR": "Senior Underwriter",
      "ACC_SERV_MGR_QL": "Account Services Manager",
      "SNR_COMM_SER_QL": "Senior Commercial Services",
      "SALES_QL": "Sales",
      "ACC_SERV_MGR": "Account Services Manager",
      "COMM_SERV_SL": "Commercial Services",
      "PRIME": "Prime",
      "COMM_SERV": "Commercial Services",
      "AREA_SAL_MGR_QL": "Area Sales Manager",
      "SNR_PRIME_QL": "Senior Prime",
      "PROP_CAS_QL": "Property & Casualty",
      "AREA_REM_MGR_QL": "Remarketing",
      "N_PRIME_QL": "Non-Prime - Primary",
      "ACC_SERV_REP": "Account Services Representative",
      "PRIME_QL": "Prime - Primary",
      "DIR_SALES_QL": "Director of Sales",
      "SNR_COMM_SER": "Senior Commercial Services",
      "AREA_REM_MGR": "Area Remarketing Manager",
      "RV_QL": "RV",
      "N_PRIME": "Non-Prime",
      "SNR_N_PRIME_QL": "Senior Non-Prime",
      "PROP_CAS": "Property & Casualty"
    }
  }
}