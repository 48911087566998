import {Injectable, NgZone} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import {Observable} from 'rxjs/Observable';
import { catchError, flatMap } from 'rxjs/operators';

import { UserModel } from '../models/user-model';
import { IUser } from '../models/user.interface';
import { IFactor } from '../otp/models/factor.interface';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { get, includes } from 'lodash';

import { DealershipRegistration } from '../registration/models/dealership-registration.model';
import { IPhoneNumber } from '../otp/models/phone-number.interface';
import { AppConstantsService } from './app-constants.service';
import { SessionManagementService, ExternalUserGroup } from './session-management.service';
import { LaunchDarklyService } from '../services/launchdarkly/launchdarkly.service';

@Injectable()
export class AuthenticationService {

  private handleError;
  private authenticationApiData=[];

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  allyUserLoggingOut: boolean = false;

  constructor(
    private http: HttpClient,
    public httpErrorHandler: HttpErrorHandlerService,
    private constantsSevice: AppConstantsService,
    private sessionService: SessionManagementService,
    private router: Router,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private ld: LaunchDarklyService,
  ) {
    this.handleError = httpErrorHandler.createHandleError('userAuthentication');
  }

  public getUserDetails(userId, password): Observable<{} | UserModel[]> {
    return this.sessionService.generateFingerprint().pipe(
      flatMap(fingerprint => this.authenticateUser(userId, password, fingerprint))
    );
  }

  authenticateUser(userId: string, password: string, fingerprint: string = ''): Observable<{} | UserModel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': fingerprint //DRP-9938: we will pass finger print as this header because apigee doesn't allow X-Device-Fingerprint header due to cors whitelisting policy.
    });

    const url = `${environment.ndaServerURLS.userServices}/authn`;

    const request = { resource: { userId, password } };

    return this.http.post<UserModel[]>(url, request, { headers, withCredentials: true })
      .pipe(
        catchError(this.handleError('userAuthentication', []))
      );
  };

  public createUser(model: DealershipRegistration) {
    const user = {
      firstName:   model.firstName,
      lastName:    model.lastName,
      email:       model.email,
      login:       model.username,
      cuid:        model.username,
      mobilePhone: model.cellPhone,
      password:    model.password,
    };

    const url = `${environment.ndaServerURLS.userServices}/public/user`;
    return this.http.post(url, user, this.httpOptions);
  }

  public createExternalUser(model: DealershipRegistration, userName: string) {
    const user = {
      firstName:   model.firstName,
      lastName:    model.lastName,
      email:       model.email,
      login:       model.username,
      cuid:        model.username,
      mobilePhone: model.cellPhone,
      password:    model.password,
    };

    const url = `${environment.ndaServerURLS.userServices}/public/user/${userName}/updateExternalUser`;
    return this.http.put(url, user, this.httpOptions);
  }

  public findUserById(id: string): Observable<IUser> {
    const url = `${environment.ndaServerURLS.userServices}/user/${id}`;
    return this.http.get<IUser>(url, this.httpOptions);
  }

  public createSMSFactor(userId: string, phoneNumber: string): Observable<IFactor> {
    const url = `${environment.ndaServerURLS.userServices}${this.sessionService.hasSession ? '' : '/public'}/user/${userId}/sms-factor`;
    return this.http.post<IFactor>(url, { phoneNumber }, this.httpOptions);
  }

  public onNextVisitLogout(): void {
    this.sessionService.willDeleteSessionOnNextVisit = true;
  }

  public logout(): void {
    let groups = this.sessionService.externalGroups || [];

    if (this.sessionService.isBranchUser) {
      groups.push(ExternalUserGroup.Ally);
      this.allyUserLoggingOut = true;
    }

    this.sessionService.deleteSession().subscribe(
      ()  => this.handleLogout(groups),
      err => console && console.error(err)
    );
  }

  private handleLogout(groups: ExternalUserGroup[]): void {
    sessionStorage.clear();
    if (includes(groups, ExternalUserGroup.Ally)) {
      window.location.href = get(environment, 'userLogoutURL.ally');
    }
    else if (includes(groups, ExternalUserGroup.Mitsubishi)) {
      window.location.href = `${window.location.origin}/ui`
    }
    else if (this.activatedRoute.snapshot.queryParams.closeTab) {
      window.top.close();
    }
    else {
      this.zone.run(()=>{
        this.router.navigate(['/login']);
        // Repopulates storage with Launch Darkly flags only after session information is cleared for logout.
        this.ld.writeFlagsToStorage();
      });
    }
  }
}

